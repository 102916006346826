import React from 'react'
import './Plans.css'
import {plansData} from'../../data/plansData'
import whiteTick from '../../assets/whiteTick.png'

const Plans = () => {
  return (
    <div className="plans-container">
      <div className="blur plans-blur1"></div>
      <div className="blur plans-blur2"></div>
      {/* <div className="blur plans-blur3"></div> */}
        {/* chera be surate flex kar nemikone?! mire khate badi */}
        <div className="programs-header" style={{gap:"2rem"}} >
          <div className='stroke-text'>READY TO START</div>
          <div>YOUR JOURNEY</div>
          <div className='stroke-text'>NOW WITH US</div>  
        </div>

        <div className="plans">
            {plansData.map((plan)=>(

            <div className="plan">
                    {plan.icon}
                    <span>{plan.name}</span>
                    <span>$ {plan.price}</span>

              <div className="features">
                 {plan.features.map((feature,)=>(
                     <div className="feature">
                           <img src={whiteTick} alt="" />
                           <span >{feature}</span>
                     </div>
                         ))}
              </div>

              <div>
                 <span>see more benefits -&gt;</span>
              </div>

              <button className='btn'>Join Now</button>

            </div>

            ))}
        </div>

    </div>
  )
}

export default Plans