import React, { useState, useRef, useEffect } from 'react'
import './Header.css'
import Logo from '../../assets/logo.png'
import Bars from '../../assets/bars.png'
import { Link } from 'react-scroll'

const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false
  const [menuOpened, setMenuOpened] = useState(false)
  const navRef = useRef(null)

  useEffect(() => {
    // Function to check if a click happens outside the navigation bar
    const handleClickOutside = (event) => {
      // Check if the navRef exists and if the clicked element is outside the nav
      if (navRef.current && !navRef.current.contains(event.target)) {
        setMenuOpened(false); // Close the menu
      }
    };

    // Attach the 'mousedown' event listener to the whole document
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener when the component unmounts or updates
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="header">

      <img className="Logo" src={Logo} />

      {(menuOpened === false && mobile === true) ? (
        <div
          style={{ background: 'var(--appColor)', padding: '0.5rem', borderRadius: '5px' }}
          onClick={() => setMenuOpened(true)}
        >
          <img src={Bars} alt=""
            style={{ width: '1.5rem', height: '1.5rem' }} />
        </div>
      ) :
        <ul ref={navRef} className='header-menu'>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              activeClass='active'
              to='hero'
              spy={true}
              smooth={true}
            >Home</Link>
          </li>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              to='Programs'
              spy={true}
              smooth={true}
            >Programs</Link>
          </li>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              to='Reasons'
              spy={true}
              smooth={true}
            >why Us</Link>
          </li>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              to='plans-container'
              spy={true}
              smooth={true}
            >Plans</Link>
          </li>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              to='Testimonials'
              spy={true}
              smooth={true}
            >Testimonials</Link>
          </li>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              to='Join'
              spy={true}
              smooth={true}
            >Join us</Link>
          </li>
        </ul>}

    </div>
  )
}

export default Header